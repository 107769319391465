import copydeck from './copydeck.csv'

// console.log(copydeck);

export default {
    get(KEY){
        let matchingElem = copydeck.find((elem)=>{
            return elem[0].trim() == KEY;
        })
        if(matchingElem) {
            return matchingElem[1].trim();
        }
        return KEY;
    }
}