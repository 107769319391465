<template>
  <main>
  <div class="image-container">
  <img class="image-container_image" src="/homescreens/screen1.jpg" aria-hidden="true" alt="" />
  <img class="image-container_image" src="/homescreens/screen2.jpg" aria-hidden="true" alt="" />
  <img class="image-container_image" src="/homescreens/screen3.jpg" aria-hidden="true" alt="" />
  <img class="image-container_image" src="/homescreens/screen4.jpg" aria-hidden="true" alt="" />
  <img class="image-container_image" src="/homescreens/screen5.jpg" aria-hidden="true" alt="" />
  <img class="image-container_image" src="/homescreens/screen6.jpg" aria-hidden="true" alt="" />
  <img class="image-container_image" src="/homescreens/screen7.jpg" aria-hidden="true" alt="" />       
  <img class="image-container_image" src="/homescreens/screen1-mobile.jpg" aria-hidden="true" alt="" />
  <img class="image-container_image" src="/homescreens/screen2-mobile.jpg" aria-hidden="true" alt="" />
  <img class="image-container_image" src="/homescreens/screen3-mobile.jpg" aria-hidden="true" alt="" />
  <img class="image-container_image" src="/homescreens/screen4-mobile.jpg" aria-hidden="true" alt="" />
  <img class="image-container_image" src="/homescreens/screen5-mobile.jpg" aria-hidden="true" alt="" />
  <img class="image-container_image" src="/homescreens/screen6-mobile.jpg" aria-hidden="true" alt="" />
  <img class="image-container_image" src="/homescreens/screen7-mobile.jpg" aria-hidden="true" alt="" />    
</div>
	  <div id="slide" class="slide">
		  <div class="home">
			  <img @click="openQuiz" id="decouvrir" src="../assets/images/decouvrir.png">
			  <img id="slogan" src="../assets/images/slogan.png">
		 </div>
		  <div class="title mobile">Un bon vin se sert avec tout,<br> avec modération surtout.</div> 
		  <div class="title desktop">Un bon vin se sert avec tout, avec modération surtout.</div> 
	  </div>
  </main>
</template>

<script>

export default {
	name: 'confirm',
	data: function () {
		return {
			width: document.documentElement.clientWidth,
			slide: 1,
			slideloop: undefined
		};
	},
	beforeRouteLeave(to, from, next) {
		clearInterval(this.slideloop);
	    next()
	},
  metaInfo() {
 //   console.log("metaInfo", this.$route.params.pathMatch.split('/share/')[1])
 //   let route = this.$route.params.pathMatch.split('/share/')[1];
  //  return {
  //    title: `Food et vin`,
  //    meta: [
  //        {name: 'description', content: 'Un bon vin se sert avec tout, avec modération surtout.'},
  //        {property: 'og:title', content: 'Food et vin'},
  //        {property: 'og:site_name', content: 'Food et vin'},
  //        {property: 'og:url', content: 'https://food-et-vin.com/'},
  //        {property: 'og:image', content: (route)?('https://food-et-vin.com/images/share/' + route + '.png'):'https://food-et-vin.com/images/share/default.png'}    
  //    ]
  //  }
  },  
	mounted() {
    this.slidebg();
    console.log("mounted", this.$route.params)

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const anim = urlParams.get('anim')
    if(anim == 'rst') {
      this.startAnimation()
    }
  },
	created() {
		window.addEventListener("resize", this.resizeHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.resizeHandler);
	},
	methods: {
    startAnimation() {
       this.clearlopp()
       let header = document.getElementById("header");
       let slogan = document.getElementById("slogan");   
       let decouvrir = document.getElementById("decouvrir");
       let slide = document.getElementById("slide");

        decouvrir.style.display = "none";
        header.classList.add("start");
  
        slogan.style.display = "inline";
        slogan.style.opacity = 1;

        setTimeout(() => { 
          slogan.classList.add("off");   
        }, 5000);

        setTimeout(() => { 
          decouvrir.style.display = "block";
          slogan.style.display = "none";
          decouvrir.classList.add("start");  
           this.slidebg()          
        }, 7000);       
    },
		resizeHandler(e) {
			this.width = e.currentTarget.innerWidth
		},
		clearlopp() {

  		if (this.width > 700) {     slide.classList.remove('startdesktop')   } else {   slide.classList.remove('startmobile')   }
			clearInterval(this.slideloop);
			this.slide = 1;
		},
		slidebg: function () {
				if (this.width > 700) {slide.classList.add('startdesktop')} else { slide.classList.add('startmobile')	}      
			this.slideloop = window.setInterval(() => {
				if (this.slide > 7) this.slide = 1
				if (this.width > 700) {
			//		slide.style.backgroundImage = "url('/homescreens/screen" + this.slide + ".jpg')";
				} else {
				//	slide.style.backgroundImage = "url('/homescreens/screen" + this.slide + "-mobile.jpg')";
				}
			this.slide++
			}, 1000);      
		},
		openQuiz: function () {
			clearInterval(this.slideloop);
			this.$router.push("/quiz");
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../scss/_variables.scss";

.slide {
  width: 100%;
  height: 100vh;
  // min-height:680px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  background-image: url('/homescreens/screen1.jpg');
  background-size: cover;
  background-position: 50% 50%;
  // background-position: top center;
  background-repeat: no-repeat;
  z-index: 1;

  .home {
    width: 100%;
    text-align: center;

    img {
      width: 772px;
    }
  }

  #slogan {
    opacity: 0;
    display: none;
  }

  #decouvrir {
    width: 470px;
    margin: 0 auto;
    margin-top: 100px;
    cursor: pointer;
  }

  @media screen and (max-width: 1970px) {
  //  background-position: 50% 60%;
  }

  @media screen and (max-width: 768px) {
    background-image: url('/homescreens/screen1-mobile.jpg');
    background-position: 50% 0;
  }

  @media screen and (max-width: 700px) {
    background-size: 100% auto;
    background-position: 0px -50px;
  }

  @media screen and (max-width: 600px) {
    background-position: 0px -20px;
  }

  @media screen and (max-width: 570px) {
    background-size: cover;
    background-position: 0px 0px;
  }

  @media screen and (max-width: 545px) {
      #slogan {
        width: 300px;
      }

      #decouvrir {
        width: 80%;
      }
    }
}



.title {
  font-family: $font-extra;
  font-weight: bold;
  font-style: italic;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 40px;
  color: white;
  font-size: 50px;

  @media screen and (max-width: 900px) {
  font-size: 40px;
    bottom: 35px;
  }  

  @media screen and (max-width: 768px) {
  font-size: 40px;
  line-height:40px;
  bottom: 60px;
  }
}


.startmobile {
  animation-name: whilemobile;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}

.startdesktop {
  animation-name: whiledesktop;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}



@keyframes whiledesktop {
  14% {
  background-image: url('/homescreens/screen1.jpg');
  }
  28% {
  background-image: url('/homescreens/screen2.jpg');
  }
  42% {
  background-image: url('/homescreens/screen3.jpg');
  }
  56% {
  background-image: url('/homescreens/screen4.jpg');
  } 
  70% {
  background-image: url('/homescreens/screen5.jpg');
  }
  88% {
  background-image: url('/homescreens/screen6.jpg');
  } 
  100% {
  background-image: url('/homescreens/screen7.jpg');
  }  
}


@keyframes whilemobile {
  14% {
  background-image: url('/homescreens/screen1-mobile.jpg');
  }
  28% {
  background-image: url('/homescreens/screen2-mobile.jpg');
  }
  42% {
  background-image: url('/homescreens/screen3-mobile.jpg');
  }
  56% {
  background-image: url('/homescreens/screen4-mobile.jpg');
  } 
  70% {
  background-image: url('/homescreens/screen5-mobile.jpg');
  }
  84% {
  background-image: url('/homescreens/screen6-mobile.jpg');
  } 
  100% {
  background-image: url('/homescreens/screen7-mobile.jpg');
  }  
}

.image-container {
   visibility: hidden;
   position: fixed;
 }
 
 .image-container_image {
   visibility: hidden;
   position: fixed;
 }
</style>