<template>
  <div v-if="promocookie" class="promo-cookie-wrapper">
    <div class="promo-cookie-popup">
      <div class="promo-cookie-content">
        <div v-if="promocookie_step == 0">
          <!-- <img class="cookie-image" :src="`${image}`" /> -->
          <h3> {{ title }}</h3>
          <h1>{{ subtitle }}</h1>
          <p>
            {{ principal_text }}
          </p>
          <p>Est-ce que vous êtes d'accord ?</p>
        </div>
        <div class="promo-next" v-if="promocookie_step == 1">
          <h1>Analytics & Stats</h1>
          <h3></h3>
          <p>
            Nous devons compter et mesurer le traffic sur ce site Web. Quelle
            page vous affichez, combien de temps vous restez sur la page, etc.
          </p>
          <ul>
            <li>
              <label class="promo-label">
                <h2><img src="../assets/images/logogoogle.png" />Google analytics</h2>
                <p>
                  Analyse les statistiques de consultation de notre site
                </p>
              </label
              ><label class="switch" for="checkboxGoogle">
                <input type="checkbox" id="checkboxGoogle" v-model="consents.google"/>
                <div class="slider round"></div>
              </label>
            </li>
            <br>
            <li>
              <label class="promo-label">
                <h2><img src="../assets/images/logofb.png" />Facebook Pixel</h2>
                <p>
                  Identifier les visiteurs en provenance de Facebook
                </p>
              </label
              ><label class="switch" for="checkboxFacebookPixel">
                <input type="checkbox" id="checkboxFacebookPixel" v-model="consents.facebookPixel"/>
                <div class="slider round"></div>
              </label>
            </li>
          </ul>
        </div>
      </div>
      <div class="promo-cookie-menu">
        <div class="cookie-nav">
          <ul>
            <li v-if="promocookie_step == 0" @click="closePromoCookie">
              Non merci
            </li>
            <li v-if="promocookie_step > 0" @click="promocookie_step--">
              Retour
            </li>
            <li v-if="promocookie_step == 0" @click="promocookie_step++">
              Je veux choisir
            </li>
            <li v-if="promocookie_step > 0" @click="acceptAllPromoCookie">
              J'accepte tout
            </li>
            <li v-if="promocookie_step == 0" @click="acceptAllPromoCookie">
              OK!
            </li>
            <li v-if="promocookie_step == 1" @click="choosePromoCookie()">
              Valider
            </li>
            <li v-else-if="promocookie_step > 0" @click="acceptAllPromoCookie">
              Suivant
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import image from "../assets/images/cookies-clip-art-17.png"

export default {
  name: "App",
  props : {
    principal_text: {
        type: String,
        default: "Nous utilisons les cookies afin de fournir les services et fonctionnalités proposés sur notre site et afin d’améliorer l’expérience de nos utilisateurs. Les cookies sont des données qui sont téléchargés ou stockés sur votre ordinateur ou sur tout autre appareil."
    },
    title : {
        type: String,
        default: ""
    },    
     subtitle : {
        type: String,
        default: ""
    }, 
    image: {
      type: String,
      default: image
    }    
  },
  data: function () {
    return {
      promocookie_step: 0,
      promocookie: true,
      consents: {
        google: false,
        facebookPixel: false,
      }
    };
  },
  mounted() {
    console.log("COOKIELAW", localStorage.promocookie)
    if (window.promocookie == 0 || localStorage.promocookie) {
      this.promocookie = false;
    }
    if(localStorage.getItem("promocookie-google")) {
      if(window.gtag) {
        window.gtag('consent', 'update', {
          'ad_storage': 'granted',
          'analytics_storage': 'granted'
        });
      }
    }
    if(localStorage.getItem("promocookie-facebookPixel")) {
      if(window.fbq) {
        window.fbq('consent', 'grant');
      }
    }
  },
  methods: {
    closePromoCookie: function () {
      window.promocookie = 0;
      document
        .querySelector(".promo-cookie-wrapper")
        .classList.add("promo-cookie-close");
    },
    choosePromoCookie() {
      window.promocookie = 0;
      if(this.consents.google) {
        if(window.gtag) {
          window.gtag('consent', 'update', {
            'ad_storage': 'granted',
            'analytics_storage': 'granted'
          });
        }
        localStorage.setItem("promocookie-google", true);
      }
      if(this.consents.facebookPixel) {
        if(window.fbq) {
          window.fbq('consent', 'grant');
        }
        localStorage.setItem("promocookie-facebookPixel", true);
      }
      localStorage.setItem("promocookie", true);
      document
        .querySelector(".promo-cookie-wrapper")
        .classList.add("promo-cookie-close");
      this.promocookie = false;
      console.log("choosePromoCookie_1");
    },
    acceptAllPromoCookie () {
      localStorage.setItem("promocookie", true);
      document
        .querySelector(".promo-cookie-wrapper")
        .classList.add("promo-cookie-close");

      localStorage.setItem("promocookie-google", true);
      localStorage.setItem("promocookie-facebookPixel", true);

      if(window.fbq) {
        window.fbq('consent', 'grant');
      }
      if(window.gtag) {
        window.gtag('consent', 'update', {
          'ad_storage': 'granted',
          'analytics_storage': 'granted'
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../scss/_variables.scss";

.promo-cookie-wrapper {
  z-index: 99999;
  position: fixed;
  bottom: 50px;
  left: -600px;
 
  @media screen and (min-width: 900px) { 
  -webkit-animation: opencook 2s 1 forwards;
  -moz-animation: opencook 2s 1 forwards;
  -o-animation: opencook 2s 1 forwards;
  animation: opencook 2s 1 forwards;
  }

  @media screen and (max-width: 900px) {
     bottom:0;
     left:0;
    -webkit-animation: opencookmobile 2s 1 forwards;
    -moz-animation: opencookmobile 2s 1 forwards;
    -o-animation: opencookmobile 2s 1 forwards;
    animation: opencookmobile 2s 1 forwards;      
  }
}
.promo-cookie-close {

  @media screen and (min-width: 900px) {  
    -webkit-animation: closecook 1s 1 forwards;
    -moz-animation: closecook 1s 1 forwards;
    -o-animation: closecook 1s 1 forwards;
    animation: closecook 1s 1 forwards;
  } 

  @media screen and (max-width: 900px) {  
    -webkit-animation: closecookmobile 1s 1 forwards;
    -moz-animation: closecookmobile 1s 1 forwards;
    -o-animation: closecookmobile 1s 1 forwards;
    animation: closecookmobile 1s 1 forwards;
  }   
}

@-webkit-keyframes opencookmobile {
  0% {
    bottom: -300px;
  }
  100% {
    bottom: 30px;
  }
}


@-webkit-keyframes closecookmobile {
  0% {
    bottom: 30px;
  }
  100% {
    bottom: -300px;
  }
}

@-webkit-keyframes closecook {
  0% {
    left: 20px;
  }
  100% {
    left: -600px;
  }
}
@-webkit-keyframes opencook {
  0% {
    left: -600px;
  }
  100% {
    left: 20px;
  }
}
.promo-cookie-popup {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.15);
  width: 450px;
  position: relative;
  color: black;

 @media screen and (max-width: 900px) { 
     width: 100%;

 }

}
.promo-cookie-popup a {
  color: black;
}
.promo-cookie-content {
  position: relative;
  padding:20px;
   @media screen and (max-width: 900px) { 
     padding:20px 0px;
     width: 95%;
     margin:0 auto;
 }
}
.promo-cookie-content h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  @media screen and (max-width: 900px) { 
     width:100%;
     text-align:center;
  }  
}
.promo-cookie-content h1 {
  margin: 0;
  padding: 0;
  font-size: 27px;
  @media screen and (max-width: 900px) { 
     width:100%;
     text-align:center;
  }
}
.promo-cookie-content p {
  font-size: 15px;
  @media screen and (max-width: 900px) { 
     width:100%;
     text-align:center;
  }

}
.promo-cookie-menu {
  width: 100%;
}
.cookie-nav ul {
  border-top: solid 1px #ccc;
  list-style-type: none;
  display: flex;
  width: 100%;
  margin: 0;
  padding: 0;
}
.cookie-nav ul li {
  width: 33.33333%;
  text-align: center;
  padding: 10px 0px;
  cursor: pointer;
  font-weight: bold;
}
.cookie-nav ul li:hover {
  color: #845541;
}
.cookie-image {
  position: absolute;
  width: 100px;
  right: -10px;
  top: -30px;

  @media screen and (max-width: 900px) {
  width: 80px;
  right: 2px;
  top: -45px;    
  }  
}
.promo-next h1,
.promo-next h3 {
  width: 100%;
  text-align: center;
}
.promo-next ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: solid 2px #ccc;
  border-radius: 10px;
  padding: 15px 15px;
  width: 90%;
}
.promo-next ul li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.promo-next ul li h2,
.promo-next ul li p {
  width: 90%;
  font-size: 17px;
  padding: 0;
  margin: 0;
}
.promo-next ul li h2 img {
  width: 15px;
  margin-right: 5px;
  vertical-align: middle;
}
.promo-next ul li p {
  font-size: 12px;
  @media screen and (max-width: 900px) {
     text-align:left;
  }
}
.promo-next ul li .switch {
  display: inline-block;
  height: 20px;
  position: relative;
  width: 40px;
}
.promo-next ul li .switch input {
  display: none;
}
.promo-next ul li .slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}
.promo-next ul li .slider:before {
  background-color: #fff;
  bottom: 2px;
  content: "";
  height: 16px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 16px;
}
.promo-next ul li input:checked + .slider {
  background-color: #66bb6a;
}
.promo-next ul li input:checked + .slider:before {
  transform: translateX(16px);
}
.promo-next ul li .slider.round {
  border-radius: 34px;
}
.promo-next ul li .slider.round:before {
  border-radius: 50%;
}

.promo-label {
  width: 320px;

  @media screen and (max-width: 900px) {
    width: 270px;  
    text-align: left;
  }    
}
</style>