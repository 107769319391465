<template>
  <header id="header">
    <Drawer @close="toggle" align="right" :closeable="true" :maskClosable="true">
      <div v-if="open">
        <ul>
          <li @click="toggle">
            <router-link to="/profils">{{$CD('MENU_ACCORD_FOOD_VINS')}} <span>&gt;</span></router-link>
          </li>
          <li @click="toggle">
            <router-link to="/campagne">{{$CD('MENU_MANIFESTO')}} <span>&gt;</span></router-link>
          </li>
          <li @click="toggle">
            <router-link to="/foodistar">{{$CD('MENU_INTERVIEWS')}} <span>&gt;</span></router-link>
          </li>
          <li @click="toggle">
            <router-link to="/?anim=rst">{{$CD('MENU_LANDING')}} <span>&gt;</span></router-link>
          </li>
        </ul>
      </div>
    </Drawer>
    <div class="header">
      <div>
        <router-link to="/?anim=rst"><img class="logo" src="../assets/images/logo.jpg"></router-link>
      </div>
      <div><img @click="toggle" class="menu-img" src="../assets/images/accordion.png"></div>
    </div>
  </header>
</template>
<script>
import Drawer from "vue-simple-drawer"

export default {
  name: 'HeaderElem',
  data() {
    return {
      open: false
    }
  },
  components: {
    Drawer
  },
  methods: {
    toggle() {
      this.open= !this.open
    }
  }
}
</script>
<style lang="scss">
@import "../scss/_variables.scss";

.vue-simple-drawer.right {
  width: 360px;
  background-color: rgba(0, 0, 0, .8);
  color: white;
  @media screen and (min-width: $mobile-width) {
    bottom: auto !important;
  }

  ul {
    list-style-type: none;
    width: 100%;

    li a {
      display: block;
      text-align: right;
      font-family: $font-extra;
      font-weight: bold;
      font-style: italic;
      color: white;
      font-size: 35px;
      text-decoration: none;
      margin-top: 50px;

      span {
        display: inline-block;
        margin-left: 20px;
      }
    }

    li a:hover {
      color: #f59836;
    }

  }

  @media screen and (max-width: $mobile-width) {
    width: 100%;
    text-align: right;

    ul li a {
      font-size: 30px;
      width: 100%;
    }
  }
}

.header {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;
  background-color: rgba(0, 0, 0, .7);
  box-shadow: 0px 15px 10px -15px #111;
  z-index: 4;

  .logo {
    width: 146px;
    margin-left: 16px;
  }

  .menu-img {
    cursor: pointer;
    margin-right: 70px;
    width: 37px;
  }

  @media screen and (max-width: 2000px) {
    height: 84px;

    .logo {
      margin-left: 10px;
      width: 120px;
      margin-top:3px;
    }

    .menu-img {
      margin-right: 28px;
      width: 30px;
    }
  }
}
</style>
