<template>
  <div id="app" class="app_wrapper">
      <div v-if="!majeur" class="majeur_wrapper">
        <img class="logo-majeur" src="./assets/images/logo.jpg">
        <div class="majeur">
          <p v-html="$CD('QUESTION_18ANS')"></p>
          <div v-if="pasrep">
            <button @click="closeMajeur">{{$CD('QUESTION_18ANS_OUI')}}</button>
            <button @click="pasrep = false">{{$CD('QUESTION_18ANS_NON')}}</button>
          </div>
          <div v-else>
            <p style="font-size: 40px !important; line-height: 40px !important;" v-html="$CD('QUESTION_18ANS_DESOLE')"></p>
          </div>
      </div>
      </div> 
      <HeaderElem></HeaderElem>
      <router-view  ref="slide" />
      <FooterElem></FooterElem>
      <CookieLaw></CookieLaw>
  </div>
</template>

<script>
  import HeaderElem from '@/components/Header'
  import FooterElem from '@/components/Footer'
  import CookieLaw from '@/components/PromodevCookieslaw'

  export default {
    name: 'App',
    data: function () {
      return {
        majeur: false,
        slide : 1,
        pasrep: true,
      }
    },
    mounted() {
        this.preload()
        window.addEventListener("load", () =>{ if(!window.pageYOffset){ this.hideAddressBar(); } } );
        window.addEventListener("orientationchange", this.hideAddressBar );

        if(window.location.pathname == '/' && !localStorage.majeur) {  // si home on clear le slide
           setTimeout(() => {  this.$refs.slide.clearlopp() }, 2000);
        }
        if(localStorage.majeur) this.majeur = localStorage.majeur
    },
    metaInfo() {
        return {
          title: `Food et vin`,
          meta: [
            {
              name: "description",
              content:
                "Découvrez votre profil food et vin. Un bon vin se sert avec tout, avec modération surtout ! Les hommes et les femmes de la vigne et du vin vous proposent des accords mets et vins qui vous correspondent !",
            },
            { property: "og:title", content: "Quel foodista êtes-vous ?" },
            { property: "og:site_name", content: "Quel foodista êtes-vous ?" },
            { property: "og:url", content: "https://food-et-vin.com/" },
            {
              property: "og:image",
              content: this.$route.params._profil
                ? "https://food-et-vin.com/images/share/" +
                  this.$route.params._profil +
                  ".png"
                : "https://food-et-vin.com/images/share/default.png",
            },
          ],
        };
      },
    methods : {
      preload : function (images) {
      if (document.images) {

				var img1 = new Image();
				var img2 = new Image();
				var img3 = new Image();
				var img4 = new Image();
				var img5 = new Image();
				var img6 = new Image();
				var img7 = new Image();
				var img8 = new Image();
				var img9 = new Image();
 				var img10 = new Image();
				var img11 = new Image();
				var img12 = new Image();
				var img13 = new Image();
				var img14 = new Image();
				var img15 = new Image();
  			var img16 = new Image();
				var img17 = new Image();
				var img18 = new Image();
				var img19 = new Image();
				var img20 = new Image();                                         
				var img21 = new Image();   

        img1.src = "/homescreens/screen1.jpg";
        img2.src = "/homescreens/screen2.jpg";
        img3.src = "/homescreens/screen3.jpg";
        img4.src = "/homescreens/screen4.jpg";
        img5.src = "/homescreens/screen5.jpg";
        img6.src = "/homescreens/screen6.jpg";
        img7.src = "/homescreens/screen7.jpg";
        img8.src = "/homescreens/screen1-mobile.jpg";
        img9.src = "/homescreens/screen2-mobile.jpg";
        img10.src = "/homescreens/screen3-mobile.jpg";
        img11.src = "/homescreens/screen4-mobile.jpg"; 
        img12.src = "/homescreens/screen5-mobile.jpg"; 
        img13.src = "/homescreens/screen6-mobile.jpg";
        img14.src = "/homescreens/screen7-mobile.jpg";    
        img15.src = "/profils/fond-aperitivore.jpg";
        img16.src = "/profils/fond-bec-sucre.jpg";
        img17.src = "/profils/fond-gastronomaniac.jpg";
        img18.src = "/profils/fond-street-lover.jpg";
        img19.src = "/profils/fond-traditionista.jpg";
        img20.src = "/profils/fond-veggie-gourmand.jpg";
        img21.src = "/profils/fond-bbq-master.jpg";
			}
			}, 
      hideAddressBar : function ()
      {
        if(!window.location.hash)
        {
            if(document.height < window.outerHeight)
            {
                document.body.style.height = (window.outerHeight + 50) + 'px';
            }
            setTimeout( function(){ window.scrollTo(0, 1); }, 50 );
        }
      },
      closeMajeur : function() {
        if(window.location.pathname == '/') {  
        localStorage.setItem('majeur', true);
        this.majeur = localStorage.majeur
        this.$refs.slide.startAnimation()

      } else {
        localStorage.setItem('majeur', true);
        this.majeur = localStorage.majeur
      }},     
    },
    components: {
      HeaderElem,
      FooterElem,
      CookieLaw
    }
  }
</script>
<style lang="scss">
@import "./scss/_variables.scss";

.logo-majeur {
  position: absolute;
  top:10px;
  left:10px;
  width:150px;
}

.majeur_wrapper {
   position: absolute;
   width:100%;
   height:100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   background-image: url('./assets/images/fond-18.jpg');
   background-size: cover;
   background-position: 50% 100%;
   background-repeat: no-repeat; 
   z-index: 75;

  .majeur {
    width: 370px;
    
    p {
      width:100%;
      text-align: center;
      color:white;
      font-family:$font-extra;
      font-style: italic;
      font-weight: bold;
      font-size:75px;
      line-height:75px;
      margin-bottom:50px;
    }

    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      button {
        font-family: "acumin-pro", sans-serif;
        font-weight: 500;    
        background-color:white;
        color: #26223b;
        border:none;
        font-size: 25px;
        padding:20px 40px;
        border-radius:20px;
        cursor: pointer;
      }
    }
    @media screen and (max-width: $mobile-width) {
      p { 
        font-size: 60px; 
        line-height: 60px;
        }
    }
  }
}

.start {
	animation-name: start;
	animation-duration: 2s;   
}

.off {
	animation-name: off;
	animation-duration: 2.5s;   
}

@-webkit-keyframes start {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}	

@-webkit-keyframes off {
  0%   { opacity: 1; }
  100% { opacity: 0; }
}	

</style>